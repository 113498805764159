var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{on:{"click":function($event){return _vm.markAllAsRead()}}},[_vm._v(_vm._s(_vm.$t("mark_all_notifications_as_read")))]),_c('table-overview',{attrs:{"store":"notifications","title":"Notifications","icon":"far fa-fw fa-bell","headers":[
      { text: 'Title', value: 'data.title', sortable: false },
      { text: 'Message', value: 'message', sortable: false },
      { text: 'Created at', value: 'created_at', type: 'id' },
      { text: 'Read', value: 'isRead', sortable: false }
    ],"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('notifications-table-row',{attrs:{"notification-id":item}})}}])},[_c('notifications-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }