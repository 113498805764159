<template>
  <tr>
    <td>
      {{ notification.data.title }}
    </td>
    <td>
      {{ notification.data.message }}
    </td>
    <td>
      {{ notification.created_at | formatDate }}
    </td>
    <td>
      <div v-if="notification.isRead">
        {{ $t("read") }}
      </div>
      <div v-else>
        <a v-on:click="markAsRead(notification.id)">{{ $t("mark_as_read") }}</a>
      </div>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters } = createNamespacedHelpers("notifications");
export default {
  name: "notifications-table-row",
  components: {},
  props: {
    notificationId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([FIND_BY_ID]),
    notification() {
      return this[FIND_BY_ID](this.notificationId);
    }
  },
  methods: {
    markAsRead(notificationId) {
      this.$store.dispatch("notifications/markAsRead", notificationId);
    }
  }
};
</script>
