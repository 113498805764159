<template>
  <v-form>
    <v-radio-group v-model="read">
      <v-radio :label="$t('all_states')" :value="null"></v-radio>
      <v-radio :label="$t('read')" value="true"></v-radio>
      <v-radio :label="$t('not_read')" value="false"></v-radio>
    </v-radio-group>
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("notifications");

export default {
  name: "notifications-table-filter",
  components: {},
  computed: {
    ...mapComputedFilters(["read"])
  }
};
</script>
