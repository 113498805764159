<template>
  <div>
    <v-btn v-on:click="markAllAsRead()">{{
      $t("mark_all_notifications_as_read")
    }}</v-btn>
    <table-overview
      store="notifications"
      title="Notifications"
      icon="far fa-fw fa-bell"
      :headers="[
        { text: 'Title', value: 'data.title', sortable: false },
        { text: 'Message', value: 'message', sortable: false },
        { text: 'Created at', value: 'created_at', type: 'id' },
        { text: 'Read', value: 'isRead', sortable: false }
      ]"
      force-initial-sort
    >
      <notifications-table-row
        slot="table-row"
        slot-scope="{ item }"
        :notification-id="item"
      />
      <notifications-table-filter slot="filters" />
    </table-overview>
  </div>
</template>

<script type="text/babel">
import NotificationsTableRow from "@/components/notification/NotificationTableRow";
import NotificationsTableFilter from "@/components/notification/NotificationTableFilter";
import TableOverview from "@/components/generic/overview/TableOverview";
import notificationsModule from "@/store/modules/notifications";
import { mapOverviewLifecycleMethods } from "@/views/helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "notifications",
  notificationsModule
);
import store from "@/store";

export default {
  name: "notifications-overview",
  components: {
    TableOverview,
    NotificationsTableFilter,
    NotificationsTableRow
  },
  ...overviewLifecycleMethods,
  metaInfo: () => ({
    title: `notifications`
  }),
  methods: {
    markAllAsRead() {
      store.dispatch("notifications/markAllAsRead");
    }
  }
};
</script>
